import { Box, keyframes, LinearProgress, ThemeProvider } from "@mui/material";
import { useNavigation, useRevalidator } from "@remix-run/react";

import { themeV2 } from "~/utils/theme";

export function PageLoadingProgressBar() {
  const navigation = useNavigation();
  const revalidator = useRevalidator();

  const active = navigation.state !== "idle" || revalidator.state !== "idle";

  return (
    <ThemeProvider theme={themeV2}>
      <Box
        aria-hidden={!active}
        sx={{
          width: "100%",
          position: "fixed",
          // The zIndex 1400 is the same as the Snackbar (https://mui.com/material-ui/customization/z-index/)
          zIndex: 1400,
          top: 0,
          opacity: active ? 1 : 0,
          animation: active ? "none" : `${fadeOut} 0.3s ease-in-out`,
        }}
      >
        <LinearProgress />
      </Box>
    </ThemeProvider>
  );
}

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

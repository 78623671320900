import { useLocation } from "@remix-run/react";
import { useEffect } from "react";

import { PLATFORM_GOOGLE_ANALYTICS_ID } from "~/constants/platform";

export function GoogleAnalyticsScript() {
  const location = useLocation();

  useEffect(() => {
    // @ts-ignore - gtag is added by the Google Analytics script
    window?.gtag?.("config", PLATFORM_GOOGLE_ANALYTICS_ID, {
      page_path: location.pathname,
    });
  }, [location.pathname]);

  return (
    <>
      <script
        async={true} // Try to fix the hydration issue
        src={`https://www.googletagmanager.com/gtag/js?id=${PLATFORM_GOOGLE_ANALYTICS_ID}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag("js", new Date());
              gtag("config", "${PLATFORM_GOOGLE_ANALYTICS_ID}");
          `,
        }}
      />
    </>
  );
}

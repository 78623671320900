const FEATURES = [
  "Intl.Locale",
  "Intl.PluralRules",
  "Intl.PluralRules.~locale.en",
  "Intl.NumberFormat",
  "Intl.NumberFormat.~locale.en",
  "Intl.DisplayNames",
  "Intl.DisplayNames.~locale.en",
].join(",");

export function PolyfillScript() {
  return <script src={`https://polyfill.io/v3/polyfill.min.js?features=${FEATURES}`}></script>;
}

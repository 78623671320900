import { withEmotionCache } from "@emotion/react";
import { unstable_useEnhancedEffect as useEnhancedEffect, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { cssBundleHref } from "@remix-run/css-bundle";
import type {
  LinkDescriptor,
  LoaderFunctionArgs,
  MetaDescriptor,
  SerializeFrom } from
"@remix-run/node";
import { json } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  type ShouldRevalidateFunction,
  useMatches,
  useRouteError } from
"@remix-run/react";
import * as Sentry from "@sentry/remix";
import type { ReactNode } from "react";
import { useContext, useRef } from "react";
import { useHydrated } from "remix-utils/use-hydrated";
import { v1 as uuidv1 } from "uuid";

import globalStylesheet from "~/styles/globals.css";
import { dayjs } from "~/utils/dayjs";
import { env } from "~/utils/env.server";

import { PageLoadingProgressBar } from "./components/page-loading-progress-bar";
import { GoogleAnalyticsScript } from "./components/scripts/google-analytics-script";
import { GtmScript } from "./components/scripts/google-tag-manager-script";
import { IntercomScript } from "./components/scripts/intercom-script";
import { PolyfillScript } from "./components/scripts/polyfill-script";
import { Routes } from "./constants/routes";
import { useAuth } from "./hooks/use-auth";
import { SearchParamsNoReloadProvider } from "./hooks/use-search-params-no-reload";
import type { Banner, BannerType } from "./modules/banner/types";
import { RouteErrorPage } from "./modules/error/pages/route-error-page";
import { LegacyLeaderboardAlert } from "./modules/leaderboard";
import { NotificationContextProvider, transformNotifications } from "./modules/notifications";
import { ReplayProvider } from "./modules/sentry";
import { WisherContextProvider } from "./modules/wisher/context/wisher-context";
import { ClientStyleContext } from "./styles/client-style-context";
import { ServerStyleContext } from "./styles/server-style-context";
import type { Auth, LegacyNotification } from "./types";
import { getCookieCurrency, getPreferredLocale } from "./utils/cookies.server";
import { createWtf } from "./utils/fetch.server";
import { ensureWwwSubdomain, upgradeToHttps } from "./utils/url.server";

export function links(): LinkDescriptor[] {
  return [
  ...(cssBundleHref ?
  [
  {
    rel: "stylesheet",
    href: cssBundleHref
  }] :

  []),
  {
    rel: "stylesheet",
    href: globalStylesheet
  },
  {
    rel: "apple-touch-icon",
    href: "/logo192.png"
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css?family=Figtree:400,500,600&display=swap"
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/icon?family=Material+Icons"
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap"
  },
  {
    rel: "stylesheet",
    href: "https://unpkg.com/swiper@10.0.4/swiper.css"
  },
  {
    rel: "stylesheet",
    href: "https://unpkg.com/swiper@10.0.4/swiper-bundle.css"
  }];

}

export function meta(): MetaDescriptor[] {
  return [
  {
    title: "Wishtender"
  },
  {
    name: "description",
    content: "Universal wishlist. Receive and give gifts safely and easily."
  },
  {
    property: "twitter:card",
    content: "summary"
  },
  {
    property: "twitter:site",
    content: "@wishtender"
  },
  {
    property: "twitter:title",
    content: "WishTender Wishlists"
  },
  {
    property: "twitter:description",
    content: "Receive or give gifts safely and easily."
  },
  {
    property: "twitter:image",
    content: "https://www.wishtender.com/images/twitter_card.png"
  }];

}

type DocumentProps = {
  children: ReactNode;
  title?: string;
};

export async function loader({ request }: LoaderFunctionArgs) {
  const https = upgradeToHttps(request);
  if (https) return https;
  const www = ensureWwwSubdomain(request);
  if (www) return www;
  const url = new URL(request.url);

  const responseHeaders = new Headers();

  const locale = getPreferredLocale(request);
  const cookieCurrency = getCookieCurrency(request);

  const wtf = createWtf(request);
  const [currentAuthResponse, bannersResponse] = await Promise.all([
  wtf("/api/current/auth"),
  wtf("/api/banners", {}, { json: true })]
  );
  const responseSetCookie = currentAuthResponse.headers.get("set-cookie");
  const authData = await currentAuthResponse.json();

  const currency = authData.currency;
  const auth: Auth = authData.auth;

  // Allow redirect route to collect cookie
  // instead of overriding the session ID cookie
  if (url.pathname !== Routes.redirect()) {
    // Setup response headers
    if (responseSetCookie) {
      responseHeaders.append("set-cookie", responseSetCookie);
    }
    if (!cookieCurrency) {
      const cookieDomain = url.hostname.endsWith("wishtender.com") ? "domain=wishtender.com;" : "";
      responseHeaders.append("set-cookie", `currency=${currency}; ${cookieDomain} path=/;`);
    }
  }

  // Legacy Notification
  const cartItemCount: number = authData.cartItemCount ?? 0;

  let newNotifications = [];
  if (auth.wisher) {
    const notificationsResponse = await wtf("/api/notifications/new");
    if (notificationsResponse.status === 200) {
      newNotifications = await notificationsResponse.json();
    }
  }

  const legacyNotification: LegacyNotification = {
    cartItemCount,
    newNotifications: transformNotifications(newNotifications)
  };

  const banners = bannersResponse.banners as Banner<BannerType>[];

  return json(
    {
      origin: url.hostname === "localhost" ? url.origin : url.origin.replace("http://", "https://"),
      auth,
      currency,
      locale,
      legacyNotification,
      ENV: {
        NODE_ENV: env.NODE_ENV,
        TARGET_ENV: env.TARGET_ENV,
        SENTRY_RELEASE:
        process.env.VERCEL_GIT_COMMIT_SHA || process.env.VERCEL_URL || env.SOURCE_VERSION,
        SENTRY_DSN: env.SENTRY_DSN,
        REACT_APP_BASE_URL: env.REACT_APP_BASE_URL
      },
      banners
    },
    {
      headers: responseHeaders
    }
  );
}

export function shouldRevalidate({
  nextUrl,
  defaultShouldRevalidate
}: Parameters<ShouldRevalidateFunction>[0]) {
  // Always revalidate root loader when navigating to the login page
  // Fix the issue user logged out in another tab, session is invalid and navigate to the login page but root loader data is not updated
  if (nextUrl.pathname === Routes.auth.login()) return true;

  return defaultShouldRevalidate;
}

const Document = withEmotionCache(({ children, title }: DocumentProps, emotionCache) => {
  // Using useMatches() here to get the data from the root loader, because cannot use useLoaderData() in ErrorBoundary
  // In ErrorBoundary, we need root loader data to render navigation bar and footer
  const matches = useMatches();
  const data = matches.find((match) => match.id === "root")?.data as SerializeFrom<typeof loader>;

  const serverStyleData = useContext(ServerStyleContext);
  const clientStyleData = useContext(ClientStyleContext);
  const reinjectStylesReference = useRef(true);

  // Only executed on client
  useEnhancedEffect(() => {
    if (!reinjectStylesReference.current) {
      return;
    }
    // re-link sheet container
    emotionCache.sheet.container = document.head;

    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    for (const tag of tags) {
      // eslint-disable-next-line no-underscore-dangle
      (emotionCache.sheet as any)._insertTag(tag);
    }

    // reset cache to re-apply global styles
    clientStyleData.reset();
    // ensure we only do this once per mount
    reinjectStylesReference.current = false;
  }, [clientStyleData, emotionCache.sheet]);

  return (
    <Layout title={title} ENV={data?.ENV || {}} serverStyleData={serverStyleData}>
      <ReplayProvider>
        <LocalizationProvider dateLibInstance={dayjs} dateAdapter={AdapterDayjs}>
          <SearchParamsNoReloadProvider>
            <NotificationContextProvider>
              <WisherContextProvider>{children}</WisherContextProvider>
            </NotificationContextProvider>
          </SearchParamsNoReloadProvider>
        </LocalizationProvider>
      </ReplayProvider>
      <ScrollRestoration />
      <Scripts />
      <LiveReload />
    </Layout>);

});

function App() {
  // export default function App() {
  return (
    <Document>
      <PageLoadingProgressBar />
      <Outlet />
      <LegacyLeaderboardAlert /> {/* TODO: Remove(leaderboard old flow) */}
    </Document>);

}

export default Sentry.withSentry(App, {
  wrapWithErrorBoundary: true,
  errorBoundaryOptions: {
    fallback: ({ error, eventId }) => {
      return <ErrorBoundary error={error} eventId={eventId} />;
    }
  }
});

export function ErrorBoundary(props: {error?: Error;eventId?: string;}) {
  const auth = useAuth();
  const isHydrated = useHydrated();
  const routeError = useRouteError();
  const error = props.error ?? routeError;

  if (isRouteErrorResponse(error)) {
    return (
      <Document>
        <RouteErrorPage error={error} />
      </Document>);

  }

  if (
  error instanceof Error &&
  error.message.includes("Failed to execute 'insertBefore' on 'Node'"))
  {
    return (
      <ErrorLayout>
        <h1>Error loading page with translate extension</h1>
        <p>Disable translation for this page and hit refresh</p>
        {props.eventId &&
        <p>
            Error ID: <code>{props.eventId}</code>
          </p>}

      </ErrorLayout>);

  }

  if (typeof navigator !== "undefined" && !navigator.onLine) {
    return (
      <ErrorLayout>
        <h1>Network error</h1>
        <p>This could be a problem with your internet connection. Please try again later.</p>
        <div>
          <form method="get">
            <button type="submit">Refresh</button>
          </form>
        </div>
        <div style={{ marginTop: 32 }} />
      </ErrorLayout>);

  }

  console.error(error);

  const errorId = uuidv1();
  if (isHydrated && !props.eventId) {
    // for some reason there's a FE error that's not captured by Sentry.ErrorBoundary
    // we should send this error to Sentry
    Sentry.captureException(error, (scope) => {
      scope.setTag("error_boundary_uncaught", true);
      if (auth?.user?.id) {
        scope.setUser({ id: auth.user.id });
      }
      return scope;
    });
  }

  return (
    <ErrorLayout>
      <h1>Uh oh ...</h1>
      <p>
        Something went wrong. Please reach out to us in the customer support chat or at{" "}
        <a href="mailto:support@wishtender.com">support@wishtender.com</a> for further assistance.
      </p>

      <p>
        Error ID: <code>{props.eventId ?? errorId}</code>
      </p>

      <div style={{ marginTop: 8 }} />

      <div>
        <form method="get">
          <button type="submit">Refresh</button>
        </form>
      </div>

      <div style={{ marginTop: 32 }} />
    </ErrorLayout>);

}

function ErrorLayout({ children }: {children: ReactNode;}) {
  return (
    <Layout serverStyleData={undefined}>
      <div
        style={{
          marginTop: "-72px",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          display: "grid",
          placeContent: "center"
        }}>

        <div
          style={{
            maxWidth: "720px",
            minWidth: "300px",
            width: "100%",
            paddingLeft: 16,
            paddingRight: 16,
            border: "1px solid #ddd",
            borderRadius: 8,
            display: "flex",
            flexDirection: "column"
          }}>

          {children}
        </div>
      </div>
    </Layout>);

}

function Layout({
  title,
  ENV,
  serverStyleData,
  children





}: {title?: string;ENV?: any;serverStyleData: any;children: ReactNode;}) {
  const theme = useTheme();
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="theme-color" content={theme.palette.primary.main} />
        {title ? <title>{title}</title> : undefined}
        <Meta />
        <Links />

        {serverStyleData?.map(({ key, ids, css }: any) =>
        <style
          key={key}
          data-emotion={`${key} ${ids.join(" ")}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: css }} />

        )}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify(ENV)};`
          }} />

        <PolyfillScript />
        <GtmScript.Head />
        <GoogleAnalyticsScript />
        <script src="https://js.stripe.com/v3/" />
      </head>
      <body>
        {/* GTM scripts should be immediately after the opening body> tag */}
        <GtmScript.Body />

        {children}

        {/* Intercom scripts should be right before the closing body tag */}
        <IntercomScript />
      </body>
    </html>);

}
/* eslint-disable jsx-a11y/iframe-has-title */

import { PLATFORM_GOOGLE_TAG_MANAGER_ID } from "~/constants/platform";

function GtmHeadScript() {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${PLATFORM_GOOGLE_TAG_MANAGER_ID}');
          `,
      }}
    />
  );
}

function GtmBodyScript() {
  return (
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<iframe
            src="https://www.googletagmanager.com/ns.html?id=${PLATFORM_GOOGLE_TAG_MANAGER_ID}"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>`,
      }}
    />
  );
}

export const GtmScript = {
  Head: GtmHeadScript,
  Body: GtmBodyScript,
};
